/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-typography h1,
.mat-typography .h1,
.mat-typography h2,
.mat-typography .h2,
.mat-typography h3,
.mat-typography .h3,
.mat-typography h4,
.mat-typography .h4,
.mat-typography h5,
.mat-typography .h5,
.mat-typography h6,
.mat-typography .h6 {
  margin-top: 0 !important;
  margin-bottom: 0.5rem ;
  font-family: "Oswald", sans-serif;
  font-weight: 500 !important;
  line-height: 1.2 !important;
  color: #2b2825;
}
